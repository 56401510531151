import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";

import { GlossButtonOptions } from "@bitwarden/web-vault/app/models/types/general-types";

/**
 * GlossButtonComponent is a reusable button component for the application.
 * It accepts an options object of type GlossButtonOptions as an @Input.
 * The options object can contain iconName, buttonText, and an onClick method.
 *
 * @example
 * <app-gloss-button [options]="{ iconName: 'arrow_back', buttonText: 'Previous', onClick: previous }"></app-gloss-button>
 */

//TODO add disabled state, and loading state
@Component({
  selector: "app-gloss-button",
  templateUrl: "./gloss-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossButtonComponent implements OnInit {
  /**
   * The options object passed to the component. It should be of type GlossButtonOptions.
   * It can contain iconName, buttonText, and an onClick method.
   */
  @Input() options: GlossButtonOptions;
  @Input() isEnabled = true;

  private actionButtonTexts: string[] = ["Next", "Save"];
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.options.enableButton = this.enableButton.bind(this);
  }

  /**
   * Method to be called when the button is clicked.
   * If an onClick method is provided in the options object, it will be called.
   */
  onButtonClicked() {
    this.enableButton(false); // default behaviour to allow click only once
    this.options.onClick();
  }

  // used to enable buttons after confirmation dialogues or validation
  enableButton(value: boolean) {
    this.isEnabled = value;
    this.changeDetectorRef.markForCheck();
  }

  isActionButton() {
    return this.actionButtonTexts.includes(this.options.buttonText);
  }
}
