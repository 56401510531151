import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { StepModel } from "./step.model";

const STEPS = [
  { stepIndex: 1, isComplete: false },
  { stepIndex: 2, isComplete: false },
  { stepIndex: 3, isComplete: false },
];

@Injectable({
  providedIn: "root",
})
export class StepsService {
  private steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  private currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);
  private renewStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  startWizard(): void {
    const wizardStepper = localStorage.getItem("wizardStepper");
    if (wizardStepper) {
      const localWizardStepper = JSON.parse(wizardStepper);
      for (let i = 0; i < localWizardStepper.stepIndex - 1; i++) {
        this.steps$.value[i] = { stepIndex: i + 1, isComplete: true };
      }
      this.steps$.value[localWizardStepper.stepIndex - 1] = localWizardStepper;
      this.currentStep$.next(localWizardStepper);
    } else {
      this.currentStep$.next(this.steps$.value[0]);

      localStorage.setItem("wizardStepper", JSON.stringify(this.steps$.value[0]));
    }
  }

  resetSteps(): void {
    this.steps$.next(STEPS.map((step) => ({ ...step, isComplete: false })));
    const initialStep: StepModel = { ...STEPS[0], isComplete: false };
    this.currentStep$.next(initialStep);
  }

  setCurrentStep(step: StepModel): void {
    this.steps$.value[step.stepIndex - 1] = step;
    this.currentStep$.next(step);

    localStorage.setItem("wizardStepper", JSON.stringify(step));
  }

  setStep(stepIndex: number, isComplete: boolean): void {
    if (stepIndex < this.steps$.value.length + 1) {
      for (let i = 0; i < stepIndex - 1; i++) {
        this.steps$.value[i] = { stepIndex: i + 1, isComplete: true };
      }
    }
    const newStep = { stepIndex: stepIndex, isComplete: isComplete };
    this.steps$.value[stepIndex - 1] = { stepIndex: stepIndex, isComplete: isComplete };
    this.currentStep$.next(newStep);
  }

  getCurrentStep() {
    return this.currentStep$.getValue();
  }

  getCurrentStepOb() {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  getStepsValue() {
    return this.steps$.value;
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index <= this.steps$.value.length) {
      if (index < 3) {
        this.currentStep$.next(this.steps$.value[index]);
      }
      localStorage.setItem("wizardStepper", JSON.stringify(this.steps$.value[index]));
    }

    if (index + 1 > this.steps$.value.length) {
      this.steps$.complete();
    }
  }

  getRenewStep() {
    return this.renewStep$.asObservable();
  }

  restartWizardInProgress() {
    this.steps$ = new BehaviorSubject<StepModel[]>(STEPS);
    this.currentStep$ = new BehaviorSubject<StepModel>(null);
  }

  restartWizard() {
    this.renewStep$.next(true);
  }

  restartWizardCompleted() {
    this.renewStep$.next(false);
  }
}
