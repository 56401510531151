<mat-sidenav-container autosize>
  <mat-sidenav #glossMenu mode="side" opened="true" *ngIf="this.deviceDetector.isDesktop()">
    <side-menu (changeHelpOpen)="handleHelpBoxOpen($event)"></side-menu>
  </mat-sidenav>
  <main
    class="tw-flex tw-flex-col tw-justify-between tw-overflow-auto xs:tw-fixed xs:tw-left-0 xs:tw-right-0"
  >
    <div>
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="this.deviceDetector.isDesktop()"></app-footer>
    <app-menu (changeHelpOpen)="handleHelpBoxOpen($event)" *ngIf="!this.deviceDetector.isDesktop()">
    </app-menu>
  </main>
</mat-sidenav-container>

<app-customer-support-box
  (changeHelpOpen)="handleHelpBoxOpen($event)"
  [helpOpen]="helpOpen"
  [ngClass]="{ 'tw-absolute tw-top-0 tw-bottom-0 tw-z-[2] tw-flex tw-w-full': helpOpen }"
>
</app-customer-support-box>
<div *ngIf="isWizardInitialise && openWizard()" class="wizard">
  <app-account-wizard-stepper></app-account-wizard-stepper>
</div>
