<ng-container id="gloss-menu">
  <div class="side-menu tw-flex tw-h-full tw-flex-col tw-justify-between tw-pr-3 tw-text-white">
    <div class="tw-flex tw-flex-col">
      <div class="logo-button tw-text-center" (click)="handleToggleNavigation()">
        <img
          class="tw-my-5 tw-w-[65%]"
          alt="Gloss Logo"
          src="../../../../images/icons/gloss-logo-white.png"
        />
      </div>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.dashboard }"
        [routerLink]="['/', 'primary-dashboard']"
        *appRoleScope="RoleScope.VIEW"
      >
        <mat-icon class="mat-icon-secondary">dashboard</mat-icon>
        <span>{{ "sideMenuDashboard" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/gloss-settings', 'account']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.account }"
        *appRoleScope="RoleScope.GLOSS"
      >
        <mat-icon class="mat-icon-secondary">account_balance_wallet</mat-icon>
        <span>{{ "sideMenuAccount" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/gloss-settings', 'categories']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.categories }"
        *appRoleScope="RoleScope.BETA_ACCESS"
      >
        <mat-icon class="mat-icon-secondary">category</mat-icon>
        <span>{{ "categories" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/gloss-settings', 'classifications']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.classifications }"
        *appRoleScope="RoleScope.BETA_ACCESS"
      >
        <mat-icon class="mat-icon-secondary">bookmark</mat-icon>
        <span>{{ "classifications" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/gloss-settings', 'institutions']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.institutions }"
        *appRoleScope="RoleScope.BETA_ACCESS"
      >
        <mat-icon class="mat-icon-secondary">account_balance</mat-icon>
        <span>{{ "institutions" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/', 'import-management']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.import }"
        *appRoleScope="RoleScope.BETA_ACCESS"
      >
        <mat-icon class="mat-icon-secondary">drive_folder_upload</mat-icon>
        <span>{{ "importData" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/', 'reference-management']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.reference }"
        *appRoleScope="RoleScope.EQUITY"
      >
        <mat-icon class="mat-icon-secondary">trending_up</mat-icon>
        <span>Reference Data</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/', 'estimates']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.estimates }"
        *appRoleScope="RoleScope.FORECASTING"
      >
        <mat-icon class="mat-icon-secondary">pie_chart_outline</mat-icon>
        <span>{{ "sideMenuEstimates" | i18n }}</span>
      </nav>

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/', 'sync']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.sync }"
        *appRoleScope="RoleScope.DATA_AGGREGATOR"
      >
        <mat-icon class="mat-icon-secondary">autorenew_outline</mat-icon>
        <span>{{ "sideMenuSync" | i18n }}</span>
      </nav>

      <!--      <nav-->
      <!--        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"-->
      <!--        [routerLink]="['/', 'estimates']"-->
      <!--        (click)="getActiveTab()"-->
      <!--        [ngClass]="{ 'active-class': this.currentUrl === GlossRouteEnum.estimates }"-->
      <!--        *ngIf="HelperCommon.isDevEnv()"-->
      <!--      >-->
      <!--        <mat-icon class="mat-icon-secondary">assistant_direction</mat-icon>-->
      <!--        <span>{{ "sideMenuPaths" | i18n }}</span>-->
      <!--      </nav>-->

      <nav
        class="tw-flex tw-cursor-pointer tw-gap-2.5 tw-p-[7px_25px]"
        [routerLink]="['/settings', 'account']"
        (click)="getActiveTab()"
        [ngClass]="{ 'active-class': this.settingsTab.includes(this.currentUrl) }"
        *appRoleScope="RoleScope.GLOSS"
      >
        <mat-icon class="mat-icon-secondary">settings</mat-icon>
        <span>{{ "settings" | i18n }}</span>
      </nav>
    </div>
    <div *appRoleScope="RoleScope.GLOSS" class="tw-flex tw-flex-col">
      <nav
        class="tw-flex tw-cursor-pointer tw-items-center tw-gap-2.5 tw-p-[7px_25px]"
        (click)="changeHelpOpen.emit(true)"
      >
        <svg class="tw-h-6 tw-w-7">
          <use xlink:href="images/sprite.svg#help" />
        </svg>
        <span>{{ "help" | i18n }}</span>
      </nav>
    </div>
  </div>
</ng-container>
