import { Component, OnInit, ViewChild, OnDestroy, DoCheck } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AccountBalanceComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/account-balance/account-balance.component";
import { AccountRegionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/account-regions/account-regions.component";
import { AccountAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/account-add-edit.component";
import { CreationOptionsComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/creation-options/creation-options.component";
import { InstitutionSelectionComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/institution-selection/institution-selection.component";
import {
  BasiqImportState,
  TransactionBasiqImporter,
} from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { SubscriptionService } from "@bitwarden/web-vault/app/services/DataService/subscription/subscription.service";
import { AppLoadService } from "@bitwarden/web-vault/app/services/app-load-service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { Permission } from "@bitwarden/web-vault/app/services/permission/permission";

import { StepModel } from "./step.model";
import { StepsService } from "./wizard-stepper-service";

import "./account-wizard-stepper.component.scss";

@Component({
  selector: "app-account-wizard-stepper",
  templateUrl: "./account-wizard-stepper.component.html",
  styles: ["account-wizard-stepper.component.scss"],
})
export class AccountWizardStepperComponent implements OnInit, OnDestroy, DoCheck {
  constructor(
    private stepsService: StepsService,
    public dialog: MatDialog,
    private globalService: GlobalService,
    private bookService: BookService,
    private permission: Permission,
    private subscriptionService: SubscriptionService,
    private dashboardService: DashboardService,
    protected i18nService: I18nService,
    private transactionBasiqImporter: TransactionBasiqImporter,
    private appLoadService: AppLoadService
  ) {
    /** inWizard is used to hide the progress bar in account section if basiq is used */
    localStorage.setItem("inWizard", "true");
  }

  private destroy$ = new Subject<void>();
  loading = false;
  isImporting = false;
  spinnerLoader = false;
  hasAccess = false;
  canAccessPremium: boolean;
  existingAccounts: Book[];
  booksView: AccountView[];
  actionPromise: Promise<any>;
  accountBalanceRef: MatDialogRef<AccountBalanceComponent>;
  accountAddRef: MatDialogRef<AccountAddEditComponent>;
  creationOptionsRef: MatDialogRef<CreationOptionsComponent>;
  regionOptionsRef: MatDialogRef<AccountRegionsComponent>;
  institutionsModalRef: MatDialogRef<InstitutionSelectionComponent>;
  steps$: Observable<StepModel[]>;
  protected readonly Origin = Origin;
  addAccountButton = "addAccount";
  setBalanceButton = "enterAccountData";
  viewForcastButton = "viewForecast";
  setBalanceTitle = "addAccountBalanceTitle";
  setBalanceDescription = "addAccountBalanceDescription";
  accessLoader = true;
  setBalanceProgressTitle = "setBalance";

  dataSource: MatTableDataSource<AccountView>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "institution", "type", "source", "status", "actions"];

  async ngOnInit() {
    this.appLoadService.setTrackAppLoading();
    this.stepsService.resetSteps();
    this.stepsService.startWizard();
    this.steps$ = this.stepsService.getSteps();

    const shouldCallBasiq = await this.transactionBasiqImporter.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      this.stepComplete();
    }

    this.transactionBasiqImporter.basiqImportState$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (state) => {
        this.updatedWizardState(state);
      },
    });
  }

  updatedWizardState(state: BasiqImportState) {
    if (state.started && !state.ended) {
      this.isImporting = true;
      this.setBalanceTitle = "syncingYourTransactions";
      this.setBalanceDescription = "syncingYourTransactionsDescription";
      this.setBalanceButton = "retrievingData";
      this.setBalanceProgressTitle = "retrievingTransactions";
    }

    if (state.ended && state.success) {
      this.setBalanceButton = "done";
      this.isImporting = false;
      this.stepsService.setStep(2, true);
      this.stepComplete();
      this.transactionBasiqImporter.completeBasiqImport();
      localStorage.removeItem("inWizard");
    }

    if (state.ended && !state.success) {
      this.setBalanceButton = "dataRetrievalFailed";
      // TODO : Update the wizard state to show the next step
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck() {
    const userSubscription = this.subscriptionService.getUserSubscription();
    if (!userSubscription) {
      this.subscriptionService.initSubscription();
    }

    if (this.accessLoader && userSubscription?.metadata.id) {
      this.hasAccess = this.permission.hasAccess(userSubscription);
      this.accessLoader = false;
    }
  }

  stepComplete() {
    const step = this.stepsService.getCurrentStep();
    const newStep: StepModel = {
      stepIndex: step.stepIndex,
      isComplete: true,
    };
    this.stepsService.setCurrentStep(newStep);
    this.stepsService.moveToNextStep();
  }

  buttonAvaliable(number: number) {
    return this.stepsService.getCurrentStep().stepIndex !== number;
  }

  addProgressBarClass() {
    switch (this.stepsService.getCurrentStep().stepIndex) {
      case 2:
        return "step-2";
      case 3:
        return "step-3";
    }
  }

  stepAfterComplete(number: number) {
    const curStep = this.stepsService.getCurrentStep().stepIndex;
    const steps = this.stepsService.getStepsValue();

    const buttonText = [this.addAccountButton, this.setBalanceButton, this.viewForcastButton];

    const getButtonState = (step: any) =>
      step.isComplete ? "done" : buttonText[step.stepIndex - 1];
    const getIconClass = (step: any, isCurrentStep: boolean) => {
      if (step.isComplete) {
        return isCurrentStep ? "step-check-icon-complete-latest" : "step-check-icon-complete-2";
      } else {
        return isCurrentStep ? "step-check-icon-in-progress" : "step-check-icon-empty";
      }
    };

    switch (number) {
      case 1:
        this.addAccountButton = getButtonState(steps[0]);
        return steps[0].isComplete ? "step-check-icon-1-finish" : "step-check-icon";
      case 2:
        this.setBalanceButton = getButtonState(steps[1]);
        return getIconClass(steps[1], curStep === number);
      case 3:
        this.viewForcastButton = getButtonState(steps[2]);
        return getIconClass(steps[2], curStep === number);
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add new account"
  // -------------------------------------------------------------------------------------------------------------------
  openCreationOptionsModal() {
    const dialogRef = this.dialog.open(CreationOptionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        createManually: this.createManually.bind(this),
        createByLinking: this.createByLinking.bind(this),
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        isPremiumAccount: this.hasAccess,
      },
      disableClose: true,
    });
    this.creationOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeCreationOptionsDialogue() {
    this.globalService.showMessage("info", "createAccount", "pleaseCreateAnAccountFirst");
  }

  createManually() {
    this.creationOptionsRef.close();
    this.openInstitutionSelectionModal();
  }

  createByLinking() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(AccountRegionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeRegionOptionDialogue.bind(this),
        openCreationOptionModal: this.openCreationOptionsModal.bind(this),
        setLoading: this.setLoading.bind(this),
      },
      disableClose: true,
    });
    this.regionOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeRegionOptionDialogue() {
    this.regionOptionsRef.close();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  openInstitutionSelectionModal() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(InstitutionSelectionComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeInstitutionSelectionDialogue.bind(this),
        openInstitutionDialogue: this.openInstitutionSelectionModal.bind(this),
        openCreationOptionsDialogue: this.openCreationOptionsModal.bind(this),
        openAccountAddModal: this.openAddAccountModal.bind(this),
      },
      disableClose: true,
    });
    this.institutionsModalRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeInstitutionSelectionDialogue() {
    this.institutionsModalRef.close();
  }

  openAddAccountModal(institution: Institution) {
    this.addAccount(null, institution);
  }

  addAccount(account: Book, selectedInstitution?: Institution) {
    const dialogRef = this.dialog.open(AccountAddEditComponent, {
      panelClass: "no-background-dialog",
      data: {
        actionSucceeded: this.openTransactionImportModal.bind(this),
        institution: selectedInstitution,
        closeDialogue: this.closeAccountAddModal.bind(this),
        openInstitutionSelectionModal: this.openInstitutionSelectionModal.bind(this),
      },
      disableClose: true,
    });
    this.accountAddRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  openTransactionImportModal() {
    this.accountAddRef.close();
  }

  async closeAccountAddModal() {
    this.accountAddRef.close();
    this.booksView = await this.bookService.getBooksView();
    if (this.booksView[0] !== undefined) {
      this.stepComplete();
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // create Dialogs for "add balance at selected account"
  // -------------------------------------------------------------------------------------------------------------------

  async addBalanceSelected() {
    this.booksView = await this.bookService.getBooksView();
    const firstManuelAccount = this.booksView[0];
    if (firstManuelAccount) {
      this.addBalance(firstManuelAccount);
    } else {
      this.globalService.showMessage(
        "info",
        "addAccount",
        "pleaseCreateAnAccountManuallyToAddBalance"
      );
      this.stepsService.setCurrentStep({ stepIndex: 1, isComplete: false });
    }
  }

  addBalance(account: AccountView) {
    if (account.origin !== Origin.manual) {
      this.globalService.showWarningMessage("warning", "balanceForManuelAccount");
      return;
    }
    const dialogRef = this.dialog.open(AccountBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: this.closeBalanceModal.bind(this),
      },
      disableClose: true,
    });
    this.accountBalanceRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });
  }

  async actionSucceeded(actionKey: string) {
    this.existingAccounts = await this.bookService.getAll();
    this.booksView = await this.bookService.getBooksView();
    this.dataSource = new MatTableDataSource<AccountView>(this.booksView);
    this.dataSource.paginator = this.paginator;
    this.accountAddRef?.close(this.existingAccounts);
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }

  closeBalanceModal() {
    this.accountBalanceRef.close();
    this.stepComplete();
  }

  // -------------------------------------------------------------------------------------------------------------------
  // Jump to primary dashboard
  // -------------------------------------------------------------------------------------------------------------------

  wizardExit() {
    localStorage.removeItem("inWizard");
    this.loading = true;
    this.appLoadService.trackAppLoading(true);
    this.viewForcastButton = "directToViewPage";
    this.stepComplete();
    localStorage.removeItem("wizardStepper");
    localStorage.setItem("startTutorial", "true");
  }
}
