import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { ThirdPartyTransaction } from "@bitwarden/web-vault/app/models/types/transaction.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-connector.service";
import { BasiqInstitutionConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-institution-connector";

export type DateString = string;
export type DateFormatIndex = {
  year: number;
  month: number;
  day: number;
  separator: string;
  format: string;
};
export type TimeFormatIndex = {
  hours: number;
  minutes: number;
  seconds?: number;
  is12HourFormat: boolean;
  amPm?: string;
  format: string;
};
export type TimezoneFormatIndex = {
  timezoneName: string;
  timezoneOffset: string; // Store the timezone offset in the format "-05:00"
  timezoneOffsetMinutes?: number; // Store the timezone offset (e.g., -300 for UTC-05:00)
};
export type PossibleDateIndices = {
  forYear: number[];
  forMonth: number[];
  forDay: number[];
};

export type ImportSteps = "upload" | "arrange" | "import" | "institution";
export type MoneySeparatorType = MoneySeparatorValues.decimal | MoneySeparatorValues.thousands;
export enum MoneySeparatorValues {
  decimal = "decimal",
  thousands = "thousands",
}
export type TransactionStatusType = TransactionStatusEnum;
export type DateFormatEvent = { possibleDateFormats: string[]; csvResults: any[] };
export type Country = { name: string; code: string };

export enum Origin {
  manual = "manual",
  basiq = "basiq",
  plaid = "plaid",
}

export interface GlossInputOptions {
  value?: string;
  suffix?: string;
  label: string;
  placeholder: string;
  isRequired: boolean;
  isDisabled?: boolean;
  inputBlurred: (value: string) => void;
  inputCleared: () => void;
  inputError?: (message: string) => void;
  onInput: (value: string) => void;
}

export interface GlossButtonOptions {
  iconName?: string;
  buttonText?: string;
  isEndIcon?: boolean;
  onClick?: () => void;
  enableButton?: (enabled: boolean) => void;
  isEnabled?: boolean;
}

export type SyncStatusAction = "retry" | "consent" | "connect" | "merge";

export type StatusPointResult = {
  type: "success" | "failure";
  message: string;
  messageI18nKey: string;
  actions: SyncStatusAction[];
};
export type StatusPoint = {
  key: "started-syncing" | "syncing" | "ready" | "synced" | "failed";
  icon: "error" | "sync" | "check";
  data: StatusPointResult;
};

export type SyncStatus = {
  isStarted: boolean;
  isAvailable: boolean;
  isFinished: boolean;
  accountStatus: AccountSyncStatus[];
};

export type AccountSyncStatus = {
  accountId: string;
  accountView: AccountView;
  rawTransactions: ThirdPartyTransaction[];
  isStarted: boolean;
  isCompleted: boolean;
  isMerged: boolean;
  point: StatusPoint;
  lastSyncedAt: GlossDate;
};

export type AccountOriginGroup = {
  [key in Origin]: AccountView[];
};

export type AccountStatusOriginGroup = {
  [key in Origin]: AccountSyncStatus[];
};

export type BasiqConnectorStatus = {
  self: BasiqConnector;
  institutionConnections: BasiqInstitutionConnector[];
};

export type SyncState = {
  isStarted: boolean;
  isCompleted: boolean;
  isAvailable: boolean;
  accountStatus: AccountSyncStatus[];
  basiqConnector: BasiqConnector;
  basiqInstitutionConnections: BasiqInstitutionConnector[];
};

export type GlossConnectorMessage = {
  isOpen: boolean;
  key: string;
  message: string;
  actions: SyncStatusAction[];
  messageI18nKey?: string;
  connectorOrigin?: Origin;
};

export type ScenarioMessages = {
  scenario1: string;
  scenario2: {
    oneAccount: string;
    noPositiveBalanceAccounts: string;
    onlyOnePositiveBalanceAccount: string;
    congratulations: string;
    notGettingInterest: string;
    notHaveNecessaryInfo: string;
    noInterestRate: string;
  };
  scenario3: {
    wellDone: string;
    notHaveNecessaryInfo: string;
  };
};
